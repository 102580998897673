import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { groupUserUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import InputIconLeftWhite from '@/views/shared-components/form/InputIconLeftWhite.vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default {
  components: {
    InputIconLeftWhite
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const router = useRouter()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const data = reactive({
      name: '',
      description: '',
      isActive: true,
      logo: ''
    })
    const route = useRoute()
    const id = ref(null) as any
    const submitted = ref(false)
    const title = ref('Tambah Grup User')
    const activeSetting = ref(false)
    const rules = {
      name: {
        required: helpers.withMessage('Nama grup user harus diisi', required)
      },
      description: {
        required: helpers.withMessage('Keterangan harus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, data)

    const checkId = () => {
      if (route.params.id) {
        return encryptDecriptMethods.decrypt(route.params.id)
      }
      return null
    }

    const initData = (result: any) => {
      console.log('any result', result)
      // data.guid = result.Guid
      data.name = result.Nama
      data.description = result.Keterangan
      data.isActive = result.IsActive
      data.logo = result.Logo
      // tag.value = result.CustomView ? result.CustomView.split('|') : null
    }

    const redirectBack = () => {
      router.go(-1)
    }

    const redirectToList = () => {
      router.push({ name: 'master-grup-user' })
    }

    const dataEditNotFound = () => {
      redirectToList()
      $toast.add({
        severity: 'error', detail: 'data grup user tidak ditemukan', group: 'bc', life: 3000
      })
    }

    const getDataEdit = async () => {
      id.value = checkId()
      if (id.value) {
        title.value = 'Edit Grup User'
        store.dispatch('showLoading')
        const response = await groupUserUseCase.getDataForm(id.value)
        if (!response.error) {
          if (!response.result) {
            dataEditNotFound()
          }
          initData(response.result)
        } else if (response.code === 404) {
          dataEditNotFound()
        }
        store.dispatch('hideLoading')
      }
    }

    const submitProcess = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        Nama: data.name,
        Keterangan: data.description,
        IsActive: data.isActive,
        Logo: data.logo ?? ''
      }
      const response = await groupUserUseCase.submitDataForm(id.value, dataForm)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        redirectBack()
      }
      store.dispatch('hideLoading')
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProcess()
    }

    const classIcon = () => ((activeSetting.value) ? 'arrow ml-auto rotate' : 'arrow ml-auto')

    onMounted(() => {
      getDataEdit()
    })

    return {
      id,
      v$,
      title,
      data,
      submitted,
      activeSetting,
      classIcon,
      submitData,
      redirectBack
    }
  }
}
